import * as React from "react"

const WaveBottom = () => {
    return (
        <div className="hidden lg:block h-16 mb-20 relative bg-gray-50 after:w-2/3"></div>
    )

}

export default WaveBottom
