import * as React from "react"
import {useInView} from "react-intersection-observer";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
import ProgressRing from "./progress-ring";
import ani1Image from "../images/ani_1_GIF.gif";
import ani2Image from "../images/ani_2_GIF.gif";
import ani3Image from "../images/ani_3_GIF.gif";
import ani4Image from "../images/ani_4_GIF.gif";
import ani5Image from "../images/ani_5_GIF.gif";
import {useEffect, useState} from "react";

const FeatureBlock = () => {
    const {ref, inView, entry} = useInView({threshold: .5});
    const [tabIndex, setTabIndex] = useState(0);
    const [nextTabIndex, setNextTabIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [intervalId, setIntervalId] = useState(0);
    const [isPause, setIsPause] = useState(true);
    const tabs = [
        {name: 'Track your impact', index: 0},
        {name: 'Set your goals', index: 1},
        {name: 'Source responsibly', index: 2},
        {name: 'Manage records', index: 3},
        {name: 'Increase property value', index: 4},
    ]


    function handleTabClick(index) {
        clearInterval(intervalId);
        setIsPause(false);
        setTabIndex(index);
        handleProgressRing(index + 1);
    }


    function handleProgressRing(index) {
        setProgress(0);
        if (index > 4) {
            setNextTabIndex(0);
        } else {
            setNextTabIndex(index);
        }
        const newIntervalId = setInterval(() => {
            setProgress(progress => progress + .1);
        }, 10);
        setIntervalId(newIntervalId);

    }

    useEffect(() => {
        if (progress >= 100) {
            clearInterval(intervalId);
            setProgress(0);
            handleTabClick(nextTabIndex);
        }
        if (!inView) {
            setIsPause(true);
            clearInterval(intervalId);
        } else {
            if(isPause){
                handleTabClick(0);
            }
        }
    }, [progress, intervalId, inView]);
    return (
        <div ref={ref} className="overflow-hidden lg:relative">
            <div className="pt-4 sm:pt-4 lg:py-4 bg-white">
                <div className="max-w-7xl mx-auto">
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => handleTabClick(index)}
                          selectedTabClassName="text-green-600 border-green-500"
                          selectedTabPanelClassName="animate-[fadeinout_1s_linear]"
                          className="m-0 text-gray-500">
                        <div className="">
                            <div className="border-b border-gray-200 mb-12">
                                <TabList className="m-0">
                                    <nav className="-mb-px flex" aria-label="Tabs">
                                        {tabs.map((tab) => (
                                            <Tab
                                                key={tab.index}
                                                className="border-transparent focus:border-0  hover:text-green-600 hover:border-green-500 w-1/4 py-4 px-1 mb-0 text-center border-b-2 font-medium text-sm"
                                                selectedClassName="text-green-600 border-green-500"
                                            >
                                                {tab.name}
                                            </Tab>
                                        ))}
                                    </nav>
                                </TabList>
                            </div>
                        </div>

                        <TabPanel className="">
                            <div className="w-full bg-gray-50 rounded-[30px] px-4 py-4 md:px-36 md:py-24">
                                <div className="grid md:grid-cols-2">
                                    <div className="relative">

                                        <div className=""><a href="#" className="no-underline inline-flex space-x-4"><span
                                            className="rounded bg-green-100 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">Track your impact</span></a>
                                        </div>
                                        <h2 className="text-4xl text-gray-900 font-extrabold mt-12">Measure, manage and track your impact</h2>
                                        <p className="text-base font-medium text-gray-900">Track and analyze
                                            your renovation's impact with ease using Evitat Logbook, the perfect
                                            automation tool for any homeowner.</p>

                                        <div className="flex lg:absolute left-2 bottom-4">
                                            <div
                                                className="w-[88px] h-[88px] border-green-300 text-gray-300 cursor-pointer border-2 rounded-full m-2 relative">
                                                <ChevronLeftIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div
                                                className="w-[88px] h-[88px] border-white cursor-pointer border-2 rounded-full m-2 relative"
                                                onClick={() => handleTabClick(1)}>
                                                <ProgressRing
                                                    radius={46}
                                                    stroke={2}
                                                    progress={progress}
                                                />
                                                <ChevronRightIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:float-right lg:h-[400px]">
                                            <img src={ani1Image} className="lg:h-[400px]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="">
                            <div className="w-full bg-gray-50 rounded-[30px] px-4 py-4 md:px-36 md:py-24">
                                <div className="grid md:grid-cols-2">
                                    <div className="relative">
                                        <div><a href="#" className="no-underline inline-flex space-x-4"><span
                                            className="rounded bg-green-100 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">Set your goals</span></a>
                                        </div>
                                        <h2 className="text-4xl text-gray-900 font-extrabold mt-12">Deepen the conversation with your builders and suppliers</h2>
                                        <p className="text-base font-medium text-gray-900">To make sure your
                                            renovation project is sustainable, share your goals with suppliers
                                            and builders.</p>
                                        <div className="flex lg:absolute left-2 bottom-4">
                                            <div
                                                className="w-[88px] h-[88px] border-green-500 hover:border-green-500 cursor-pointer border-2 rounded-full m-2 relative"
                                                onClick={e => handleTabClick(0)}>
                                                <ChevronLeftIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div
                                                className="w-[88px] h-[88px] border-white cursor-pointer border-2 rounded-full m-2 relative"
                                                onClick={e => handleTabClick(2)}>
                                                <ProgressRing
                                                    radius={46}
                                                    stroke={2}
                                                    progress={progress}
                                                />
                                                <ChevronRightIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:float-right lg:h-[400px]">
                                            <img src={ani2Image} className="lg:h-[400px]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="">
                            <div className="w-full bg-gray-50 rounded-[30px] px-4 py-4 md:px-36 md:py-24">
                                <div className="grid md:grid-cols-2">
                                    <div className="relative">
                                        <div><a href="#" className="no-underline inline-flex space-x-4"><span
                                            className="rounded bg-green-100 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">Source responsibly</span></a>
                                        </div>
                                        <h2 className="text-4xl text-gray-900 font-extrabold mt-12">Source materials, suppliers and specialist professionals</h2>
                                        <p className="text-base font-medium text-gray-900">Imagine finding the
                                            perfect match for any renovation project size and sustainability
                                            goal. That's why we created Evitat's Match Finder!</p>
                                        <div className="flex lg:absolute left-2 bottom-4">
                                            <div
                                                className="w-[88px] h-[88px] border-green-400 hover:border-green-500 cursor-pointer border-2 rounded-full m-2 relative"
                                                onClick={e => handleTabClick(1)}>
                                                <ChevronLeftIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div
                                                className="w-[88px] h-[88px] border-white cursor-pointer border-2 rounded-full m-2 relative"
                                                onClick={e => handleTabClick(3)}>
                                                <ProgressRing
                                                    radius={46}
                                                    stroke={2}
                                                    progress={progress}
                                                />
                                                <ChevronRightIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:float-right lg:h-[400px]">
                                            <img src={ani3Image} className="lg:h-[400px]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="">
                            <div className="w-full bg-gray-50 rounded-[30px] px-4 py-4 md:px-36 md:py-24">
                                <div className="grid md:grid-cols-2">
                                    <div className="relative">
                                        <div><a href="#" className="no-underline inline-flex space-x-4"><span
                                            className="rounded bg-green-100 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">Manage records</span></a>
                                        </div>
                                        <h2 className="text-4xl text-gray-900 font-extrabold mt-12">Access and store your documents through our asset section</h2>
                                        <p className="text-base font-medium text-gray-900">Helping you manage
                                            and quickly access your warranty and service documents in a safe and
                                            convenient place.</p>
                                        <div className="flex lg:absolute left-2 bottom-4">
                                            <div
                                                className="w-[88px] h-[88px] border-green-400 hover:border-green-500 cursor-pointer border-2 rounded-full m-2 relative"
                                                onClick={e => handleTabClick(2)}>
                                                <ChevronLeftIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div
                                                className="w-[88px] h-[88px] border-white cursor-pointer border-2 rounded-full m-2 relative"
                                                onClick={e => handleTabClick(4)}>
                                                <ProgressRing
                                                    radius={46}
                                                    stroke={2}
                                                    progress={progress}
                                                />
                                                <ChevronRightIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:float-right lg:h-[400px]">
                                            <img src={ani4Image} className="lg:h-[400px]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="">
                            <div className="w-full bg-gray-50 rounded-[30px] px-4 py-4 md:px-36 md:py-24">
                                <div className="grid md:grid-cols-2">
                                    <div className="relative">
                                        <div><a href="#" className="no-underline inline-flex space-x-4"><span
                                            className="rounded bg-green-100 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">Increase property value</span></a>
                                        </div>
                                        <h2 className="text-4xl text-gray-900 font-extrabold mt-12">Turn your
                                            choices into property value</h2>
                                        <p className="text-base font-medium text-gray-900">Evitat provides
                                            powerful, intuitive insights into the market. It will help you
                                            convert your sustainable choices into real property value.</p>
                                        <div className="flex lg:absolute left-2 bottom-4">
                                            <div
                                                className="w-[88px] h-[88px] border-green-400 hover:border-green-500 cursor-pointer border-2 rounded-full m-2 relative"
                                                onClick={e => handleTabClick(3)}>
                                                <ChevronLeftIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div
                                                className="w-[88px] h-[88px] border-white text-gray-300 cursor-pointer border-2 rounded-full m-2 relative">
                                                <ProgressRing
                                                    radius={46}
                                                    stroke={2}
                                                    progress={progress}
                                                />
                                                <ChevronRightIcon
                                                    className="h-8 w-8 absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2"
                                                    aria-hidden="true"/>

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:float-right lg:h-[400px]">
                                            <img src={ani5Image} className="lg:h-[400px]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default FeatureBlock
